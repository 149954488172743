<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="pendingTDSinvoiceList"
      class="table-rounded"
    >
      <template v-slot:item.createdAt="{ item }">
        {{ simpleFormatDate(item.createdAt) }}
      </template>
    </v-data-table>
        
    <v-divider class="my-2" v-if="alertMsg.length > 1"></v-divider>    
    <v-alert
      :type="alertType"
      v-if="alertMsg.length > 1"
      dismissible
    > 
      <span v-html="alertMsg"></span>
    </v-alert>
    <form id="_uploadReportForm">
      <input
        type="file"
        id="report_to_upload"
        name="report_to_upload"
        style="display: none"
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />
    </form>
    <v-dialog v-model="loading" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
        <v-layout justify-center align-center>
          <v-progress-circular
            indeterminate
            color="primary">
          </v-progress-circular> 
        </v-layout>
      </v-container>
    </v-dialog>
  </v-card>
</template>

<script>
import { mdiDownload, mdiUpload, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { getPendingTDSInvoices } from "../../../services/apiCall.js";
import { simpleFormatDate } from "../../../services/utils.js";

export default {
  components: {
  },
  setup(props) {
    return {
      getPendingTDSInvoices,
      simpleFormatDate,
    }
  },
  data: () => ({
    alertMsg: '',
    alertType: 'success',
    headers: [
      { text: 'Client Name', value: 'client_name' },
      { text: 'Invoice', value: 'invoice_number' },
      { text: 'Invoice Date', value: 'createdAt' },
      { text: 'Amount', value: 'before_tax_amount' },
      { text: 'TDS Amount', value: 'tds_amount' },
    ],

    statusColor: {
      Reviewed: 'warning',
      Dilevered: 'info',
      Invoiced: 'warning',
      Received: 'success',
      Pending: 'warning',
      Partial: 'info',
      Completed: 'success',
    },

    expanded: [],

    pendingTDSinvoiceList: [],
    loading: false,
    expandIcon: mdiChevronDown,

    // icons
    icons: {
      mdiDownload,
      mdiUpload,
    },
}),
  mounted: function() {
    this.init();
  },
  methods: {
    init() {
      this.fetchPendingInvoices();
    },
    fetchPendingInvoices() {
      this.getPendingTDSInvoices()
      .then(async (res) => {
        //console.log('list tds pending called:', res);
        if (res.data.status === 0) {
          this.pendingTDSinvoiceList = res.data.result.tds_pending_invoices;
        } else {
          this.alertType = "error";
          this.alertMsg = res.data.error.error_message;
        }
      })
      .catch(async (err) => {
        this.alertType = "error";
        this.alertMsg = "List pending tds incvoices failed" + ": " + err.toString();
      });
    },
  },
}
</script>
